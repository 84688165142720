<template>
  <div class="app russian-market">
    <div class="main-width fade-in">
      <div>
        <h2 class="slide-in">Russia Market</h2>
        <div>
          <p v-for="item in list" :key="item" class="list-item">{{ item }}</p>
        </div>
      </div>
      <div class="hero-content">
        <div class="hero-text">
          <ul>
            <li><p>◆  Young demographic</p></li>
            <li><p>◆  Economic growth</p></li>
            <li><p>◆  Generation Z</p></li>
            <li><p>◆  Low concentration</p></li>
          </ul>
        </div>
      </div>
      <p class="hero-text-desc">In 2023, the annual revenue of the Russian mobile gaming market exceeded  <span>$2.8 billion</span>, and it is expected to reach <span>$4 billion</span> by 2025.</p>
      <img src="../images/company-01.png" alt="" class="zoom-in">
    </div>
  </div>
</template>

<script setup>
const list = [
  `1. The internet penetration rate has significantly increased, and the mobile gaming market holds immense potential.`,
  `2. Russian players have a particular preference for shooting and MMORPG games.`,
  `3. The future size of the Russian gaming market is expected to reach approximately 200 million.`,
]
</script>

<style scoped>
.app {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 82px 0 50px 0;
  background: url('../images/theearth-l.png') no-repeat left 20%/15% auto,
              url('../images/theearth-r.png') no-repeat right 50%/15% auto;
}
.main-width {
  background: rgba(255, 255, 255, 0.8);
  padding: 0 10px;
  animation: fadeIn .7s ease-in-out;
}
h2 {
  font-size: 30px;
  font-weight: 700;
  background: #000;
  color: #fff;
  width: 220px;
  text-align: center;
  padding: 0 13px;
  font-style: italic;
  animation: slideIn .7s ease-out;
}
.hero-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.hero-content p {
  font-weight: 700;
  font-size: 20px;
}
.hero-content img {
  width: 50%;
}
.hero-text-desc {
  font-size: 20px;
  background: #FFF4F4;
  padding: 10px;
  color: #000;
}
.hero-text-desc span {
  color: #B6462F;
  font-weight: 700;
}
.russian-market img {
  width: 60%;
  animation: zoomIn 1.5s ease-in-out;
}
@media (max-width: 768px) {
	.russian-market img {
		width: 100%;
	}
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes slideIn {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}
@keyframes zoomIn {
  from { transform: scale(0.8); }
  to { transform: scale(1); }
}
.list-item {
  animation: fadeIn .7s ease-in-out;
}
</style>