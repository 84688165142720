<template>
  <div class="app company-over">
    <div class="company-logo">
      <div class="company-logo-item" :class="{'active': type === 2}">
          <div class="company-name" v-if="type === 2">
            <div>
              {{ type === 2 ? 'About CC Global' : bodyWidth > 767 ? 'About Century Huatong Group (CHT)' : 'About CHT'  }}
            </div>
          </div>
        <div class="main-width">
      <div v-if="type === 2">
        <div class="company-show-content fade-in">
          <p v-for="(item, index) in cclist" :key="index">{{ item }}</p>
        </div>
        <div class="flex-center" style="display: none;">
          <div class="flex-center logo-left slide-up">
            <img src="../images/company/img-03.png" class="img-left" alt="">
            <img src="../images/company/img-04.png" alt="">
          </div>
          <div class="logo-right slide-up">
            <img src="../images/company/img-06.png" alt="">
            <img src="../images/company/img-08.png" alt="">
            <img src="../images/company/img-05.png" alt="">
            <img src="../images/company/img-07.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="company-inner-txt">
          <div class="inner-logo" @click="type = 1" v-if="type === 2">
            <div v-if="type === 2">
              {{ bodyWidth > 767 ? 'About Century Huatong Group (CHT)' : 'About CHT' }}
            </div>
          </div>
        </div>
      </div>
      <div class="company-logo-item" :class="{'active': type === 1}">
        <div class="company-inner-txt" style="border: 0;"> 
          <div class="inner-logo" @click="type = 2" v-if="type === 1">
            About CC Global
          </div>
        </div>
        <div class="company-name" v-if="type === 1">
            <div>
              {{ type === 2 ? 'About CC Global' : bodyWidth > 767 ? 'About Century Huatong Group (CHT)' : 'About CHT'  }}
            </div>
          </div>
        <div class="main-width">
      <div class="company-show" v-if="type === 1">
        <div class="company-show-content fade-in">
          <p v-for="(item, index) in chtlist" :key="index" v-html="item"></p>
        </div>
        <div class="company-level border-bottom slide-up" style="display: none;">
          <img src="../images/company/logo-01.png" alt="">
          <img src="../images/company/logo-02.png" alt="">
          <img src="../images/company/logo-03.png" alt="">
          <img src="../images/company/logo-04.png" alt="">
          <img src="../images/company/logo-05.png" alt="">
          <img src="../images/company/logo-06.png" alt="">
        </div>
        <div class="company-level company-bot slide-up">
          <div><img style="width: 100%;" src="../images/company/level-3.png" alt=""></div>
          <div><img style="width: 100%;" src="../images/company/level-50.png" alt=""></div>
          <div><img style="width: 100%;" src="../images/company/level-100.png" alt=""></div>
          <div><img style="width: 100%;" src="../images/company/level-200.png" alt=""></div>
        </div>
        <div class="company-bot-img fade-in" style="display: none;">
          <img src="../images/company/img-01.png" alt="">
          <div class="company-bot-img-content slide-up">
            <div>
              The download count of <span class="text-fff">WHITEOUT</span> <span class="text-f77">SURVIVAL</span> has surpassed 30 million, with over <span class="text-62f">$1 billion total revenue</span>. In June 2024, its <span class="text-62f">$100 million monthly revenue</span> soared to impressive heights. In February 2024, it ranked <span class="text-62f">No.1</span> in overseas market revenue, capturing nearly 23% of the global SLG mobile game market share.
            </div>
            <div>It has consistently remained at the top of the iOS bestseller charts and performed exceptionally well in app stores across multiple countries.</div>
          </div>
        </div>
      </div>
    </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const chtlist = [
  `Zhejiang Century Huatong (SZ Stock Code: 002602) was founded in 2005 and successfully listed on the Shenzhen Stock Exchange in July 2011. The company started with its automobile parts business and began transitioning to the internet gaming industry in 2014. In 2019, it completed the acquisition of Shengqu Games (formerly known as Shanda Games) for RMB 29.8 billion (approximately USD 4.2 billion), becoming one of the leading publicly listed companies in the cultural and media sector on China’s A-share market. Currently, Zhejiang Century Huatong's core businesses encompass internet gaming, automobile parts manufacturing, and AI cloud data services. In addition, the company is actively involved in brain science research, artificial intelligence, metaverse platform and application development, as well as investment in various fields. Tencent and OCT Group are among its major shareholders.`,];

const cclist = [
  `CC Global is a leading game publisher and digital media service provider. Leveraging its extensive resources, CC Global excels in game publishing, distribution, and digital marketing solutions.`,
  `The company offers tailored digital media buying and data-driven marketing strategies, particularly for the gaming industry, ensuring maximum global exposure for its clients' products. Its deep expertise in data analytics and user insights allows CC Global to drive precise marketing campaigns that enhance brand visibility in a competitive marketplace.`,
  `In traditional advertising, CC Global integrates creative strategies across TV commercials, outdoor advertising, and other media channels to strengthen the market presence of game products. Additionally, CC Global has established strong partnerships in Japan, providing comprehensive marketing solutions, including PR, livestreams, and SNS management, helping game companies quickly enter and succeed in the Japanese market.
With its extensive cross-border experience and deep understanding of the gaming sector, CC Global is a key player in global game publishing and promotion, offering end-to-end services to help games thrive in international markets.`,
];


const type = ref(2);
const bodyWidth = ref(window.innerWidth)
onMounted(() => {
  window.addEventListener('resize', () => {
      bodyWidth.value = window.innerWidth
    })
});
onUnmounted(() => {
  window.removeEventListener('resize', () => {[]})
})
</script>

<style scoped>
.app {
  line-height: 1.6;
  padding-top: 82px;
  box-sizing: border-box;
  min-height: calc(100vh - 150px);
}
@media (max-width: 920px) {
  .app {
  padding-top: 60px;
}
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: url('../images/bg-02.jpeg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-over h2 {
  font-size: 40px;
  font-family: "Oswald", serif;
}
.company-over p {
  font-size: 20px;
  font-family: Symbol, 'Segoe UI', Helvetica, Arial, sans-serif;
  color: #000;
  padding: 0;
  margin: 0;
  padding-bottom: 15px;
}
.company-inner {
  padding-top: 90px;
}
.company-logo {
  width: 100%;
}
.company-logo-item{
  background: linear-gradient(to right, #FAF9F7, #FFB1A1, #FFEB7B);
}
.company-name {
  /* max-width: 85vw; */
  height: 75px;
  line-height: 75px;
  font-size: 30px;
  border-bottom: 1px solid #fff;
  color: #ad3d29;
  font-weight: 700;
  font-family: 'Trebuchet MS', Verdana, Helvetica, Arial, sans-serif;
}
.company-name > div {
  max-width: 85vw;
  margin: 0 auto;
}
.company-inner-txt {
  /* border-bottom: 1px solid #bbb; */
  background: #fff!important; 
}
.company-logo-item.active .company-inner-txt{
  background: transparent;
}
.inner-logo {
  max-width: 85vw;
  margin: 0 auto;
  height: 75px;
  display: flex;
  align-items: center;
  font-family: 'Trebuchet MS', Verdana, Helvetica, Arial, sans-serif;
  cursor: pointer;
  font-size: 30px;
}
.company-logo-item img {
  width: auto;
  height: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.company-logo-item.active img {
  opacity: 1;
  filter: grayscale(0%);
}
.company-logo-item .on {
  padding-left: 30px;
  height: 50%;
  /* line-height: 20px; */
  background: url("../images/Vector.png") no-repeat center bottom /30px;
  cursor: pointer;;
}
.company-logo-item.active .on {
  display: block;
}
.company-level {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
}
.company-bot {
  justify-content: space-between;
}
.company-bot-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.company-bot-img img:nth-child(2) {
  width: 52%;
}
.company-bot-img-content {
  font-size: 22px;
  color: #000;
  height: 368px;
  background: linear-gradient(to top, #637085, #CCD1D9);
  box-shadow: 5px 2px 10px 0 rgba(0, 0, 0, 0.5);
}
.company-bot-img-content div {
  padding: 12px 15px;
}
.company-bot-img-content div span {
  font-size: 23px;
  font-weight: bold;
  font-family: "Oswald", serif;
}
.border-bottom {
  border-bottom: 2px solid #B6462F;
}
.company-level img {
  width: 60%;
  height: auto;
}
.border-bottom img{
  width: 15%;
}
.company-level div {
  height: 130px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.capability-title {
  color: #fff;
  background: #000;
  font-size: 23px;
  font-family: "Oswald", serif;
  width: 130px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin: 0 auto;
  font-weight: bold;
  font-style: italic;
}
img {
  width: 50%;
  height: auto;
}
.company-logo-show {
  width: 20%;
  height: auto;
}
.company-show-content {
  margin-top: 15px;
  animation: fadeIn 1s ease-in-out;
}
.capability-item-list {
  padding-bottom: 10px;
}
.capability-item-list .title {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}
.capability-item-list .content {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  color: #000;
  padding: 0;
  margin: 0;
  padding-bottom: 15px;
}
.logo-left {
  width: 46%;
}
.logo-left img {
  height: 70%;
  width: 40%;
}
.logo-left img:nth-child(2) {
  height: 70%;
  width: 46.5%;
}
.logo-right {
  display: flex;
  flex-wrap: wrap;
  width: calc(60% + 20px);
  margin: -10px;
}
.logo-right img {
  width: 20%;
  flex: 1 1 calc(50% - 20px);
  margin: 10px;
  box-sizing: border-box;
}
.text-fff {
  color: #fff;
}
.text-f77 {
  color: #FBDF77;
}
.text-62f {
  color: #B6462F;
}
@media (max-width: 1300px) {
  .company-bot-img{
    display: block;
  }
  .company-bot-img img {
    width: 100%;
  }
  .company-bot-img-content{
    height: auto;
  }
}
@media (max-width: 767px) {
  .company-logo {
    gap: 20px;
  }
  .border-bottom img{
    width: 26%;
  }
  .company-logo-item:nth-child(2) img {
    top: 15px;
  }
  .main-width {
    width: 100%;
  }
  .capability-list {
    padding: 0 15px;
  }
  .flex-center {
    flex-direction: column;
    width: 100%;
  }
  .logo-left {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 13px;
  }
  .logo-left img {
    width: 45%;
  }
  .logo-left img:nth-child(2) {
    width: 53%;
  }
  .logo-right {
    width: 100%;
  }
  .logo-right img {
    width: 100%;
    margin: 3px 0;
  }
  .company-level {
    text-align: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
  .company-level div {
    width: 50%;
    height: 100px;
  }
  .company-bot-img {
    display: block;
  }
  .company-bot-img img {
    width: 100%;
  }
  .company-bot-img-content {
    height: auto;
    font-size: 16px;
  }
  .company-bot-img-content div span {
    font-size: 17px;
  }
  /* .fade-in {
    padding: 0 15px;
  } */
  /* .inner-logo {
    padding-left: 15px;
  } */
  .company-show-content {
    margin-top: 15px;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.slide-up {
  animation: slideUp 1s ease-in-out;
}
.header-txt {
  font-size: 30px;
  font-weight: 700;
  font-family: Symbol, 'Segoe UI', Helvetica, Arial, sans-serif;
}
</style>