<template>
  <div class="contact-us">
    <div class="contact-us-inner">
      <div class="contact-us-title">
        <div>CONTACT ME</div>
        <div>Let's create something together</div>
      </div>
      <div class="contact-us-form">
        <form method="post" class="form-group padding-small main-width" @submit.prevent="sendEmail">
          <h3>Send us a message</h3>
          <div class="form-content">
            <div class="input-row">
              <div>Your Name <span>*</span></div>
              <input type="text" v-model="form.name" name="author" id="author" required />
            </div>
            <div class="input-row">
              <div>Your Email Address <span>*</span></div>
              <input type="email" v-model="form.email" name="email" id="email" required />
            </div>
            <div class="input-row">
              <div>Content of the Message <span>*</span></div>
              <textarea v-model="form.message" id="comment" name="comment" required></textarea>
            </div>
            <button type="submit">
              {{ message ? message.text :isLoading ? 'SENDING...' : 'SEND' }}
            </button>
          </div>
        </form>
      </div>
    </div>
    
    
  </div>
</template>

<script setup>
// Add component logic here
import { reactive, ref } from 'vue'
import emailjs from '@emailjs/browser'


const form = reactive({
  name: '',
  email: '',
  message: ''
})

const isLoading = ref(false)
const message = ref(null)

const sendEmail = async () => {
  isLoading.value = true
  message.value = null

  try {
    await emailjs.send(
      'service_1rc5e3j',
      'template_rt81uiv',
      {
        from_name: form.name,
        from_email: form.email,
        message: form.message,
      },
      'vC7aeYrw37qW9wPCK'
    )

    message.value = { type: 'success', text: 'Sent successfully!' }
    form.name = ''
    form.email = ''
    form.message = ''
  } catch (error) {
    console.error('Error sending email:', error)
    message.value = { type: 'error', text: 'Sending failed!' }
  } finally {
    isLoading.value = false
  }
}

</script>

<style scoped>
/* Add component styles here */
.contact-us {
  position: relative;
  margin-top: 82px;
  overflow: hidden;
  background: url('../images/contact-us-bg.png') no-repeat center center / 100%;
  padding-top: 100px;
}
.contact-us-inner{
  display: flex;
  height: 100%;
  max-width: 85vw;
  margin: 0 auto;
}
.contact-us-title{
  margin-top: -100px;
  width: 50%;
  height: 79vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  position: relative;
  font-family: 'Trebuchet MS', Verdana, Helvetica, Arial, sans-serif;
}
.contact-us-title > :nth-child(1) {
  font-size: 60px;
  font-weight: 700;
}
.contact-us-title > :nth-child(2) {
  font-size: 30px;
  font-weight: 700;
}
.contact-us-form{
  width: 50%;
  height: 100%;
  background-color: #fff;
  border-radius: 30px;
  border: 1px solid #000;
  /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); */
  padding: 20px;
  margin-bottom: 20px;
}
.padding-small{
  padding: 10px 0;
}
.contact-us p{
 font-size:20px;
 line-height: 1;
 font-family: "Open Sans", sans-serif;
}

.contact-us h3 {
  font-size: 40px;
  font-family: "Oswald", serif;
  line-height: 1;
  padding: 0;
  margin: 0;
  height: 50px;
  text-align: center;
}

.contact-us p {
  font-size: 20px;
  line-height: 1;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 40px;
}

.form-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-content textarea {
  width: 100%;
  height: 190px;
  padding: 10px;
  box-sizing: border-box;
}

.input-row {
  justify-content: space-between;
}

.input-row div{
  line-height: 40px;
  border-radius: 10px;
}

.input-row span{
  color: #B6462F;
}
.input-row input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  outline: 0;
  border: 1px solid #333;
}
.input-row input:focus {
    background-color: #fff8d9;
    border: 1px solid #ff9824;
  }
.input-row textarea {
  border-radius: 6px;
  border: 1px solid #333;
  outline: 0;
}

.input-row textarea:focus {
  background-color: #fff8d9;
  border: 1px solid #ff9824;
}

.form-content button {
  /* width:65%; */
  min-width: 120px;
  padding: 10px 15px;
  height: 50px;
  background-color: #B6462F;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 auto;
  border-radius: 25px;
  transition: transform 0.3s ease-in-out;
}
.form-content button:hover { 
   transform:scale(1.05);
   background-color:#A03D2A;
}
.contact-us-title h3{
  width: 27vw;
  font-size: 3.5vw;
  font-family: "Oswald", serif;
  line-height: 1.3;
  padding: 0;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 333px;
  /* line-height: 70px; */
}
@media (max-width: 1100px) {
  .contact-us-title > :nth-child(1) {
  font-size: 40px;
}
.contact-us-title > :nth-child(2) {
  font-size: 20px;
}
}
@media (max-width: 768px) {
  .contact-us {
    background: url('../images/contact-us-bg-mobile.png') no-repeat center -50px / 100%;
  }
  .contact-us-inner{
    display: block;
    max-width: 100vw;
  }
  .contact-us-title > :nth-child(1) {
    font-size: 30px;
    text-align: center;
  }
  .contact-us-title > :nth-child(2) {
    font-size: 15px;
    text-align: center;
  }
  .contact-us-title{
    width: 100vw;
    height: 100px;
    margin-top: 0;
  }
  .contact-us-form{
    width: 80%;
    margin: 0 auto 20px;
    border: 0;
    /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) */
    border: 1px solid #000;
  }
  .contact-us{
    padding-top: 0;
    margin-top: 60px;
    height: auto;
  }
  .form-content textarea{
    height: 150px;
  }
  .input-row input{
    height: 42px;
  }
  .input-row div{
    line-height: 37px;
  }
  .contact-us-title h3 {
    width: 100vw;
    text-align: center;
    line-height: 10vh;
  }
  .contact-us h3{
    font-size: 22px;
    position: inherit;
    height: 10vh;
    top: auto;
    left: auto;
    margin: 0 auto;
    transform: inherit;
  }
  .contact-us p{
    font-size: 16px;
  }
  .form-content{
    gap: 10px;
    padding:0 20px;
  }
  .contact-us-inner > .contact-us-title > h3 {
    font-size: 4.5vw;
  }
}
</style>