<template>
  <div class="app">
      <section class="hero">
          <div class="hero-overlay">
              <!-- <div :class="['hero-text', { 'hero-text-show': isVisible }]">
                  Marketing and Promoting <br> Asian Game IPs WORLDWIDE <br>
                  <span>Creating an Extraordinary Gaming Universe</span>
              </div> -->
              <div class="hero-logo">
                <img :src="bodyWidth > 767 ? home_slogan : home_slogan_mob" alt="">
              </div>
              <div class="hero-txt">CREATING AN EXTRAORDINARY GAMING UNIVERSE</div>
          </div>
      </section>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import home_slogan from '../images/home_slogan.png'
import home_slogan_mob from '../images/home_slogan_mob.png'
const isVisible = ref(false);
const bodyWidth = ref(window.innerWidth)
onMounted(() => {
    setTimeout(() => {
        isVisible.value = true;
    }, 100);
    window.addEventListener('resize', () => {
      bodyWidth.value = window.innerWidth
    })
});
onUnmounted(() => {
  window.removeEventListener('resize', () => {[]})
})
</script>

<style scoped>
.app {
  line-height: 1.6;
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('../images/hero-background.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0 50px 0;

}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 85vw;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.3); */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
.hero-logo img {
  width: 40vw;
}
.hero-txt {
  font-size: 2vw;
  font-family: Symbol, 'Segoe UI', Helvetica, Arial, sans-serif;
}

.hero-text {
  color: #000;
  width: 90%;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  
  font-size: 5em; 
  text-transform: uppercase; 
  font-weight: bold; 
  line-height: 1;
  font-family: "Oswald", serif;
   
  opacity: 0; 
  transform: translateY(100px); 
  
  transition: opacity 2.5s ease, transform 2.5s ease; 
  font-family: 'Trebuchet MS', Verdana, Helvetica, Arial, sans-serif;
}

.hero-text-show {
   opacity: 1; 
   transform: translateY(0);
}

.hero-text span {
   font-size: 30px; 
   display: block; 
   margin-top: 20px;
   color: #000;
}
@media (max-width: 768px) {
  .hero {
    background-image: url('../images/hero-background-mobile.png');
  }
  .hero-logo img {
    width: 80vw;
  }
  .hero-txt {
    font-size: 4vw;
  }
}
</style>