<template>
  <div class="app">
    <div
      class="nav-links-mobile-bg"
      :class="islinksMobile ? 'show' : ''"
      @click="islinksMobile = false"
    ></div>
    <header class="header" :class="currentClass">
      <nav class="nav">
        <div class="logo">
          <router-link to="/" class="nav-link" active-class="active">
            <img src="./images/ccglobal_logo.png" alt="logo" srcset="" />
          </router-link>
        </div>
        <div
          class="nav-links-mobile"
          @click="islinksMobile = !islinksMobile"
        ></div>
        <div
          class="nav-links"
          :class="islinksMobile ? 'show' : ''"
          @click="islinksMobile = false"
        >
          <router-link
            v-for="(item, index) in navItems"
            :key="index"
            :to="item.path"
            class="nav-link"
            active-class="active"
          >
            {{ item.name }}
          </router-link>
        </div>
      </nav>
    </header>

    <router-view></router-view>

    <footer class="footer" :class="route.path === '/' ? 'footer-bg' : ''">
      <div class="main-width footer-content">
        <div class="logo">
          <router-link to="/" class="nav-link" active-class="active">
            <img src="./images/ccglobal_logo.png" alt="logo" srcset="" />
          </router-link>
          <div class="copyright">
            <div>©2024 CC Global</div>
            <div></div>
          </div>
        </div>
        <div class="footer-links">
          <a
            v-for="(link, index) in footerLinks"
            :key="index"
            :href="'#' + link.href"
            class="footer-link"
          >
            <span v-if="link.list.length === 0">{{ link.text }}</span>
            <div v-if="link.list.length > 0" class="footer-link-list">
              <span v-for="(item, index) in link.list" :key="index">{{
                item
              }}</span>
            </div>
          </a>
          <!-- <div class="email">
            <div>
              Email: info@ccglobal-game.com
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="copyright">
        <div class="main-width">
          Copyright©2024 CC Global Co.Ltd. All right Reserver
        </div>
        <div></div>
      </div> -->
      <div class="email">
        <div>
          <!-- <a href="#/contact-us" class="footer-link"> -->
          Email: info@ccglobal-game.com
          <!-- </a> -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
const navItems = [
  // { name: 'Home', path: '/' },
  { name: "About Us", path: "/company-overview" },
  { name: "Products", path: "/Cases" },
  // { name: 'Market Analysis', path: '/russian-market' },
  // { name: 'Vision and Plan', path: '/cooperation' },
  { name: "Contact", path: "/contact-us" },
];

const footerLinks = [
  { href: "/company-overview", text: "About Us", list: [] },
  { href: "/Cases", text: "Products", list: [] },
  { href: "/contact-us", text: "Contact", list: [] },
  // { href: '/russian-market', text: 'Market Analysis', list:[] },
  // { href: '/contact-us', text: 'Vision and Plan', list:[] },
  // { href: '/contact-us', text: 'Email: info@ccglobal-game.com', list:['Email: ','info@ccglobal-game.com'] },
];
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const currentClass = computed(() => route.meta.className || "");
const islinksMobile = ref(false);
</script>

<style>
@font-face {
  font-family: "MyFont1";
  src: url("@/assets/fonts/fontawesome-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("@/assets/fonts/memv.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("@/assets/fonts/TK3i.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}
.app {
  line-height: 1.6;
  overflow: hidden;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  /* border-bottom: 1px solid #000; */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1));
  backdrop-filter: blur(10px);
  /* background: url("./images/hero-background.png") no-repeat top / 2000px; */
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85vw;
  padding: 0 15px;
  margin: 0 auto;
  height: 82px;
}

.logo {
  font-weight: bold;
  color: #fff;
}
.email {
  /* padding-top: 30px;
  margin-top: 30px; */
  /* border-top: 1px solid #bbb; */
}
.email div {
  max-width: 85vw;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: right;
}
.logo img {
  height: 45px;
  margin-top: 5px;
}

.nav-links {
  display: flex;
  gap: 50px;
  font-size: 20px;
  transition: all 0.3s;
}

.nav-link {
  color: #000;
  text-decoration: none;
  position: relative;
  transition: color 0.3s;
  display: flex;
  font-family: 'Trebuchet MS', Verdana, Helvetica, Arial, sans-serif;
}

.nav-link:hover,
.nav-link.active {
  color: #b6462f;
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  /* background-color: red; */
  transform: scaleX(0);
  transition: transform 0.3s;
}

.nav-link:hover::after,
.nav-link.active::after {
  transform: scaleX(1);
}

.footer {
  background-color: white;
  color: #000;
  padding: 30px 15px;
  /* border-top: 1px solid #bbb; */
  box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.1);
}
.footer-bg {
  margin-top: 0;
  /* border-top: 1px solid #bbb; */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.footer-content > div {
  max-width: 50%;
}

.footer-logo {
  font-size: 24px;
  font-weight: bold;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.footer-link {
  width: auto;
  color: #000;
  text-decoration: none;
  font-size: 16px;
  text-align: right;
  margin-left: 10px;
}
.footer-link:hover {
  color: #ff9e0d;
}

.copyright {
  text-align: left;
  font-size: 14px;
  display: flex;
  margin: 3px auto 0;
  color: #000;
}
.nav-links-mobile {
  display: none;
  width: 30px;
  height: 30px;
  background: url("@/images/mobile-01.png") no-repeat center center;
  background-size: 100% 100%;
}
.nav-links-mobile-bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 920px) {
  .nav {
    padding: 10px 15px;
    height: auto;
  }
  .nav-link {
    color: #fff;
  }
  .nav-links-mobile {
    display: block;
  }
  .nav-links-mobile-bg.show {
    display: block;
  }
  .logo img {
    height: 40px;
    margin-top: 0;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.3s;
    padding-top: 50px;
  }

  .nav-links.show {
    display: flex;
  }

  .nav-link {
    font-size: 26px;
  }

  .footer {
    padding: 20px 15px;
    position: relative;
  }

  .footer-logo img {
    height: 30px;
  }

  .footer-links {
    grid-template-columns: 1fr;
    gap: 1px;
  }

  .footer-link {
    font-size: 14px;
  }

  .footer-link-list {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
}
@media (max-width: 767px) {
  .footer-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  .email div {
    text-align: left;
  }
}
.main-width {
  max-width: 85vw;
  margin: 0 auto;
}
/* .nav-link {
  font-family: "Oswald", serif;
} */
.home-bg {
  background-color: transparent;
}

.about-bg {
  background-color: #fff;
}
</style>
