<template>
  <div class="app cooperation-view">
    <section class="hero">
      <div class="main-width">
        <div class="container">
          <div class="data-group slide-in-left">
            <div class="image-container">
              <div class="overlay">
                <p class="overlay-text">Government Collaboration</p>
              </div>
            </div>
            <div class="text">Promote the development of the gaming industry through policy support and easier market access, creating a win-win situation.</div>
          </div>

          <div class="data-group slide-in-right">
            <div class="text">Facilitate cultural exchange by exporting Chinese culture and incorporating Russian cultural elements into games, promoting diversity and mutual understanding.</div>
            <div class="image-container">
              <div class="overlay">
                <p class="overlay-text">Cultural Exchange</p>
              </div>
            </div>
          </div>

          <div class="data-group reverse slide-in-left">
            <div class="text">
              <p>Establish game development centers or partner studios to nurture local game development talent and create employment opportunities.</p>
              <p>Collaborate with local universities to offer courses related to game development and digital technology, fostering the next generation of creative talent.</p>
              <p>Support Russia's youth innovation and entrepreneurship programs, empowering the younger generation to participate in the global digital economy.</p>
            </div>
            <div class="image-container">
              <div class="overlay">
                <p class="overlay-text">Social Responsibility</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('slide-in-visible');
      }
    });
  });

  document.querySelectorAll('.data-group').forEach(elem => {
    observer.observe(elem);
  });
});
</script>

<style scoped>
.app {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding-top: 132px;
}

.container {
  display: flex;
  flex-direction: column;
}

.data-group {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 0.8s ease-out, opacity 0.8s ease-out;
}

.data-group.reverse {
  flex-direction: row-reverse;
}

.data-group.slide-in-right {
  transform: translateX(100%);
}

.data-group.slide-in-visible {
  opacity: 1;
  transform: translateX(0);
}

.image-container {
  position: relative;
  width: 50%;
  height: 350px;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5) url('../images/cooperation-01.png') no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}
.image-container:hover .overlay {
  transform: scale(1.1);
}
.data-group:nth-child(2) .overlay {
  background-image: url('../images/cooperation-02.png');
}

.data-group:nth-child(3) .overlay {
  background-image: url('../images/cooperation-03.png');
}

.overlay-text {
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  font-family: "Oswald", serif;
}

.text {
  width: 48%;
  text-align: left;
  padding-left: 2%;
  font-size: 20px;
  line-height: 1.3;
}

@media (max-width: 768px) {
  .app {
    padding-top: 62px;
  }

  .data-group {
    display: block;
    margin-bottom: 25px;
  }

  .data-group:nth-child(2), .data-group:nth-child(3) {
    display: flex;
    justify-content: normal;
    flex-wrap: wrap;
  }

  .data-group:nth-child(2) .text, .data-group:nth-child(3) .text {
    order: 2;
  }

  .data-group:nth-child(3) p {
    margin: 0;
    margin-bottom: 10px;
  }

  .image-container {
    width: 100%;
    height: 250px;
  }

  .text {
    width: 92%;
    padding: 2% 4%;
  }
}
</style>