<template>
	<div class="app">

		<!-- <h2 class="section-title">Successful Cases in Global Markets</h2> -->
		<div class="section-title-t">
			<div>Exemplary Game Project Showcase</div>
		</div>

		<div class="main-width game-list-container">
			<ul class="game-list">
				<li v-for="game in games" :key="game.id" class="game-item">
					<div class="game-content" :style="{ backgroundImage: `url(${game.bgurl})` }">
						<img :src="game.bgtoprul" alt="" class="top-logo">
						<img :src="game.bgboturl" alt="" class="bottom-image">
						<div class="hover-content">
							<div class="games-des">
								<h3>{{ game.title }}</h3>
								<p>{{ game.des }}</p>
								<div class="play-but">
									<div class="play-on">Play on</div>
									<div class="download-buttons">
										<a :href="game.appsurl" target="_blank" class="btn btn-apple">
											<img src="images/apple.svg" alt="">
										</a>
										<a :href="game.playurl" target="_blank" class="btn btn-google">
											<img src="images/google_play.svg" alt="">
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<!-- <div class="logo-grid">
    <div v-for="(row, rowIndex) in result" :key="rowIndex" class="logo-row">
      <div v-for="(logo, logoIndex) in row" :key="logoIndex" class="logo-container">
        <div class="logo-frame">
          <img :src="logo" :alt="`Logo ${rowIndex * 5 + logoIndex + 1}`" class="logo" />
        </div>
      </div>
			</div>
		</div> -->

		<div class="logo-grid">
			<div class="logo-frame" v-for="(item) in logo_result" :key="item">
				<img :src="item" class="logo">
			</div>
		</div>

		<div class="section-title-t">
			<div>Global Collaboration Experience</div>
		</div>
		<div class="avia-content-slider-inner" ref="targetDiv" :class="{ 'active': isVisible }">

			<div class="slide-entry-wrap">
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-11 slide-parity-odd  av_one_sixth  real-thumbnail">
					<a href="https://gamewith.jp" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/GameWith-logo.jpg"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-9 slide-parity-odd  av_one_sixth  real-thumbnail">
					<a href="http://gzbrain.jp/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/Gz-logo.jpg"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-12 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://www.amoad.com/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/7.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-8 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://maio.jp/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img loading="lazy"
							decoding="async" width="220" height="120" src="images/experience/3-1.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-7 slide-parity-odd  av_one_sixth first real-thumbnail">
					<a href="https://www.pixiv.net/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/pixiv-logo-1.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-10 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://www.smartnews.com/en/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/5.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
			</div>
			<div class="slide-entry-wrap">
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-4 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://www.yahoo.co.jp/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/2-1-1.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-2 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://yoyaku-top10.jp/pc/r/ios/total" data-rel="slide-1" class="slide-image" title=""
						target="_blank"><img decoding="async" width="220" height="120" src="images/experience/22.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-5 slide-parity-odd  av_one_sixth  real-thumbnail">
					<a href="http://www.yamadalabi.com/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/24.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-1 slide-parity-odd  av_one_sixth first real-thumbnail">
					<a href="https://www.i-mobile.co.jp/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							decoding="async" width="220" height="120" src="images/experience/1-2.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-6 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://www.uuum.jp/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/uuum-logo-1.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-3 slide-parity-odd  av_one_sixth  real-thumbnail">
					<img decoding="async" width="220" height="120" src="images/experience/gamegift.jpg"
						class="attachment-portfolio size-portfolio" alt="">
				</div>
			</div>
			<div class="slide-entry-wrap">
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-17 slide-parity-odd  av_one_sixth  real-thumbnail">
					<a href="https://www.metro-ad.co.jp" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/Metro-logo.jpg"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-15 slide-parity-odd  av_one_sixth  real-thumbnail">
					<a href="https://www.gamefeat.net/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/10.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-16 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="http://www.hanjo-ten.com/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/11.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-13 slide-parity-odd  av_one_sixth first real-thumbnail">
					<a href="https://www.applovin.com/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/8.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-18 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://dotapps.jp/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/13.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-14 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://www.kayac.com" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/MBFR.jpg"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
			</div>
			<div class="slide-entry-wrap">
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-29 slide-parity-odd  av_one_sixth  real-thumbnail">
					<a href="https://line.me/zh-hans/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/27.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-26 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://www.youtube.com/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/23.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-28 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://about.twitter.com/zh-cn.html" data-rel="slide-1" class="slide-image" title=""
						target="_blank"><img loading="lazy" decoding="async" width="220" height="120" src="images/experience/26.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-27 slide-parity-odd  av_one_sixth  real-thumbnail">
					<a href="https://www.facebook.com/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/25.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-25 slide-parity-odd  av_one_sixth first real-thumbnail">
					<a href="https://www.google.com/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/28.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-30 slide-parity-even  post-entry-last  av_one_sixth  real-thumbnail">
					<a href="http://zucks.co.jp/en/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/16.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
			</div>
			<div class="slide-entry-wrap">
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-24 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://www.chartboost.com/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/19.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-22 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://www.adcolony.com/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/17.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-20 slide-parity-even  av_one_sixth  real-thumbnail">
					<a href="https://smart-c.jp/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/15.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-19 slide-parity-odd  av_one_sixth first real-thumbnail">
					<a href="https://seedapp.jp/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/14.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-23 slide-parity-odd  av_one_sixth  real-thumbnail">
					<a href="https://oct-pass.net/" data-rel="slide-1" class="slide-image" title="" target="_blank"><img
							loading="lazy" decoding="async" width="220" height="120" src="images/experience/18.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
				<div
					class="slide-entry flex_column no_margin post-entry slide-entry-overview slide-loop-21 slide-parity-odd  av_one_sixth  real-thumbnail">
					<a href="https://www.game-connection.com/zh/home-zh/" data-rel="slide-1" class="slide-image" title=""
						target="_blank"><img loading="lazy" decoding="async" width="220" height="120" src="images/experience/21.png"
							class="attachment-portfolio size-portfolio" alt=""></a>
				</div>
			</div>
		</div>

		<div class="section-title-t">
			<div>Customer Relationship</div>
		</div>
		<div id="av-masonry-1"
			class="av-masonry noHover av-fixed-size av-no-gap av-hover-overlay-active av-masonry-animation- av-masonry-col-4 av-caption-on-hover av-caption-style-overlay av-masonry-gallery avia_sortable_active">
			<div class="av-masonry-container isotope" style="position: relative; height: 967.936px;margin-bottom: 50px">
				<div onclick="return false;"
					class="av-masonry-entry isotope-item av-masonry-item-no-image av-masonry-item-loaded"
					style="position: absolute; left: 0%; top: 0px;"></div>
				<a href="images/experience/LifeAfter.jpg" id="av-masonry-1-item-2282" data-av-masonry-item="2282"
					onclick="return false;"
					class="av-masonry-entry isotope-item post-2282 attachment type-attachment status-inherit hentry av-masonry-item-with-image av-masonry-item-loaded lightbox-added"
					title="LifeAfter" itemprop="thumbnailUrl" style="position: absolute; left: 24.8594%; top: 725px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/LifeAfter.jpg);"
								title="LifeAfter"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">LifeAfter</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">NetEase Games</div>
								</div>
							</div>
						</div>
					</div>
				</a><!--end av-masonry entry--><a href="images/experience/cyber-hunter.jpg" id="av-masonry-1-item-1876"
					data-av-masonry-item="1876" onclick="return false;"
					class="av-masonry-entry isotope-item post-1876 attachment type-attachment status-inherit hentry av-masonry-item-with-image av-masonry-item-loaded lightbox-added"
					title="CyberHunter" itemprop="thumbnailUrl" style="position: absolute; left: 74.6607%; top: 483px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/cyber-hunter.jpg);"
								title="CyberHunter"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">CyberHunter</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">NetEase Games</div>
								</div>
							</div>
						</div>
					</div>
				</a><!--end av-masonry entry--><a href="images/experience/Dawn-of-Isles.jpg" id="av-masonry-1-item-2280"
					data-av-masonry-item="2280" onclick="return false;"
					class="av-masonry-entry isotope-item post-2280 attachment type-attachment status-inherit hentry av-masonry-item-with-image av-masonry-item-loaded lightbox-added"
					title="Dawn of Isles" itemprop="thumbnailUrl" style="position: absolute; left: 0%; top: 0px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container"
								style="background-image: url(images/experience/Dawn-of-Isles.jpg);" title="Dawn of Isles"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Dawn of Isles</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">NetEase Games</div>
								</div>
							</div>
						</div>
					</div>
				</a><!--end av-masonry entry--><a href="images/experience/Identity-V.jpg" id="av-masonry-1-item-2281"
					data-av-masonry-item="2281" onclick="return false;"
					class="av-masonry-entry isotope-item post-2281 attachment type-attachment status-inherit hentry av-masonry-item-with-image av-masonry-item-loaded lightbox-added"
					title="Identity V" itemprop="thumbnailUrl" style="position: absolute; left: 49.7189%; top: 725px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/Identity-V.jpg);"
								title="Identity V"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Identity V</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">NetEase Games</div>
								</div>
							</div>
						</div>
					</div>
				</a><!--end av-masonry entry--><a href="images/experience/Werewolf.jpg" id="av-masonry-1-item-2283"
					data-av-masonry-item="2283" onclick="return false;"
					class="av-masonry-entry isotope-item post-2283 attachment type-attachment status-inherit hentry av-masonry-item-with-image av-masonry-item-loaded lightbox-added"
					title="Werewolf" itemprop="thumbnailUrl" style="position: absolute; left: 24.8594%; top: 0px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/Werewolf.jpg);"
								title="Werewolf"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Werewolf</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">NetEase Games</div>
								</div>
							</div>
						</div>
					</div>
				</a><!--end av-masonry entry--><a href="images/experience/01-640x960.jpg" id="av-masonry-1-item-1790"
					data-av-masonry-item="1790" onclick="return false;"
					class="av-masonry-entry isotope-item post-1790 attachment type-attachment status-inherit hentry av-masonry-item-with-image av-masonry-item-loaded lightbox-added"
					title="The Legend of Heroes: Akatsuki no Kiseki" itemprop="thumbnailUrl" style="position: absolute; left: 0%; top: 483px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container"
								style="background-image: url(images/experience/01-640x960-470x705.jpg);"
								title="The Legend of Heroes: Akatsuki no Kiseki"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">The Legend of Heroes:
										Akatsuki no Kiseki</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">Falcom</div>
								</div>
							</div>
						</div>
					</div>
				</a><!--end av-masonry entry--><a
					href="images/experience/lALPAuoR6JogHkzNAsnNBPM_1267_713.png_620x10000q90g-e1564666430405.jpg"
					id="av-masonry-1-item-1870" data-av-masonry-item="1870" onclick="return false;"
					class="av-masonry-entry isotope-item post-1870 attachment type-attachment status-inherit hentry av-masonry-item-with-image av-masonry-item-loaded lightbox-added"
					title="Abyss Horizon" itemprop="thumbnailUrl" style="position: absolute; left: 49.7189%; top: 0px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container"
								style="background-image: url(images/experience/lALPAuoR6JogHkzNAsnNBPM_1267_713.png_620x10000q90g-e1564666430405.jpg);"
								title="Abyss Horizon"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Abyss Horizon</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">FriendTimes Inc.</div>
								</div>
							</div>
						</div>
					</div>
				</a><!--end av-masonry entry--><a href="images/experience/JDZD-pic.png" id="av-masonry-1-item-1840"
					data-av-masonry-item="1840" onclick="return false;"
					class="av-masonry-entry isotope-item post-1840 attachment type-attachment status-inherit hentry av-masonry-item-with-image av-masonry-item-loaded lightbox-added"
					title="Iron Saga" itemprop="thumbnailUrl" style="position: absolute; left: 49.7189%; top: 241px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/JDZD-pic.png);"
								title="Iron Saga"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Iron Saga</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">Game Duchy</div>
								</div>
							</div>
						</div>
					</div>
				</a><!--end av-masonry entry--><a href="images/experience/SWY-pic.png" id="av-masonry-1-item-1847"
					data-av-masonry-item="1847" onclick="return false;"
					class="av-masonry-entry isotope-item post-1847 type-attachment status-inherit hentry av-masonry-item-with-image lightbox-added av-masonry-item-loaded"
					title="God of Highschool" itemprop="thumbnailUrl" style="position: absolute; left: 74.6607%; top: 0px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/SWY-pic.png);"
								title="God of Highschool"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">God of Highschool</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">Shinezone</div>
								</div>
							</div>
						</div>
					</div>
				</a><a href="images/experience/SGZ-pic.png" id="av-masonry-1-item-1853" data-av-masonry-item="1853"
					onclick="return false;"
					class="av-masonry-entry isotope-item post-1853 type-attachment status-inherit hentry av-masonry-item-with-image lightbox-added av-masonry-item-loaded"
					title="Dynasty Legends: Zhao Yun" itemprop="thumbnailUrl" style="position: absolute; left: 74.6607%; top: 241px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/SGZ-pic.png);"
								title="Dynasty Legends: Zhao Yun"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Dynasty Legends: Zhao
										Yun</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">Yogurt Games</div>
								</div>
							</div>
						</div>
					</div>
				</a><a href="images/experience/LEWZ-pic.png" id="av-masonry-1-item-1841" data-av-masonry-item="1841"
					onclick="return false;"
					class="av-masonry-entry isotope-item post-1841 type-attachment status-inherit hentry av-masonry-item-with-image lightbox-added av-masonry-item-loaded"
					title="Last Empire War Z" itemprop="thumbnailUrl" style="position: absolute; left: 0%; top: 725px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/LEWZ-pic.png);"
								title="Last Empire War Z"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Last Empire War Z</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">Im30</div>
								</div>
							</div>
						</div>
					</div>
				</a><a href="images/experience/R1-pic.png" id="av-masonry-1-item-1844" data-av-masonry-item="1844"
					onclick="return false;"
					class="av-masonry-entry isotope-item post-1844 type-attachment status-inherit hentry av-masonry-item-with-image lightbox-added av-masonry-item-loaded"
					title="Abyss Horizon" itemprop="thumbnailUrl" style="position: absolute; left: 24.8594%; top: 241px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/R1-pic.png);"
								title="Abyss Horizon"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Abyss Horizon</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">Tenshi Games</div>
								</div>
							</div>
						</div>
					</div>
				</a><a href="images/experience/FSZHS-pic.png" id="av-masonry-1-item-1838" data-av-masonry-item="1838"
					onclick="return false;"
					class="av-masonry-entry isotope-item post-1838 type-attachment status-inherit hentry av-masonry-item-with-image lightbox-added av-masonry-item-loaded"
					title="Summoners Fate" itemprop="thumbnailUrl" style="position: absolute; left: 0%; top: 241px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/FSZHS-pic.png);"
								title="Summoners Fate"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Summoners Fate</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">RedCool Games</div>
								</div>
							</div>
						</div>
					</div>
				</a><a href="images/experience/RYHX-pic.png" id="av-masonry-1-item-1845" data-av-masonry-item="1845"
					onclick="return false;"
					class="av-masonry-entry isotope-item post-1845 type-attachment status-inherit hentry av-masonry-item-with-image lightbox-added av-masonry-item-loaded"
					title="Noah Fantasy" itemprop="thumbnailUrl" style="position: absolute; left: 74.6607%; top: 725px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/RYHX-pic.png);"
								title="Noah Fantasy"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Noah Fantasy</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">RedCool Games</div>
								</div>
							</div>
						</div>
					</div>
				</a><a href="images/experience/LM-pic.png" id="av-masonry-1-item-1842" data-av-masonry-item="1842"
					onclick="return false;"
					class="av-masonry-entry isotope-item post-1842 type-attachment status-inherit hentry av-masonry-item-with-image lightbox-added av-masonry-item-loaded"
					title="Lorde Moblie" itemprop="thumbnailUrl" style="position: absolute; left: 24.8594%; top: 483px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/LM-pic.png);"
								title="Lorde Moblie"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Lorde Moblie</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">IGG</div>
								</div>
							</div>
						</div>
					</div>
				</a><a href="images/experience/TTF-pic.png" id="av-masonry-1-item-1848" data-av-masonry-item="1848"
					onclick="return false;"
					class="av-masonry-entry isotope-item post-1848 type-attachment status-inherit hentry av-masonry-item-with-image lightbox-added av-masonry-item-loaded"
					title="Abyssrium" itemprop="thumbnailUrl" style="position: absolute; left: 49.7189%; top: 483px;">
					<div class="av-inner-masonry-sizer"></div>
					<div class="av-inner-masonry main_color">
						<div class="av-masonry-outerimage-container">
							<div class="av-masonry-image-container" style="background-image: url(images/experience/TTF-pic.png);"
								title="Abyssrium"></div>
						</div>
						<div class="av-inner-masonry-content site-background">
							<div class="av-inner-masonry-content-pos">
								<div class="av-inner-masonry-content-pos-content">
									<div class="avia-arrow"></div>
									<h3 class="av-masonry-entry-title entry-title " itemprop="headline">Abyssrium</h3>
									<div class="av-masonry-entry-content entry-content" itemprop="text">Cheetah Mobile</div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
		</div>

	</div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';


const games = [
	{ id: 1, title: 'Whiteout Survival', des: 'A magical world full of epic tales and exciting adventures! The fate of the world lies in your hand!', bgurl: 'images/games/game-bg-01.jpeg', bgtoprul: 'images/games/game-logo-01.png', bgboturl: 'images/games/game-img-01.png', appsurl: 'https://apps.apple.com/us/app/whiteout-survival/id6443575749', playurl: 'https://play.google.com/store/apps/details?id=com.gof.global' },
	{ id: 2, title: 'Family Farm Adventure', des: 'A gorgeous farming simulator game where you can harvest crops, explore mysterious islands and start your own prosperous farmtown! ', bgurl: 'images/games/game-bg-02.jpeg', bgtoprul: 'images/games/game-logo-02.png', bgboturl: 'images/games/game-img-02.png', appsurl: "https://apps.apple.com/us/app/family-farm-adventure/id1543158709", playurl: "https://play.google.com/store/apps/details?id=com.farmadventure.global" },
	{ id: 3, title: 'Dragonscapes Adventure', des: 'Rotating to align colors or finding the gap to win the challenges. Enhance cognition and reaction speed in Dragonscapes Adventure, an addictive puzzle game.', bgurl: 'images/games/game-bg-03.jpeg', bgtoprul: 'images/games/game-logo-03.png', bgboturl: 'images/games/game-img-03.png', appsurl: "https://apps.apple.com/app/dragonscapes-adventure/id1515511378", playurl: "https://play.google.com/store/apps/details?id=com.dragonscapes.global" },
	{ id: 4, title: 'Frozen City', des: 'As the chief of the last town on Earth, you have to gather adequate resources and rebuild society. Play this city-building game now!', bgurl: 'images/games/game-bg-04.jpeg', bgtoprul: 'images/games/game-logo-04.png', bgboturl: 'images/games/game-img-04.png', appsurl: "https://apps.apple.com/us/app/frozen-city/id1637040599", playurl: "https://play.google.com/store/apps/details?id=com.fct.global&hl=en&gl=US" },
	{ id: 5, title: 'Valor Legends Eternity', des: 'Challenge the Guardians of Endless Trial; Explore the Mysterious Realms; Adventure in Magic Expedition with your strongest team!', bgurl: 'images/games/game-bg-05.jpeg', bgtoprul: 'images/games/game-logo-05.png', bgboturl: 'images/games/game-img-05.png', appsurl: "https://apps.apple.com/us/app/valor-legends-eternity/id1561305688", playurl: "https://play.google.com/store/apps/details?id=com.ageofeternity.global&referrer=adjust_reftag%3Dcloyk7qoMSnd8%26utm_source%3DBranding_Homepage" },
	{ id: 6, title: 'Idle Mafia', des: 'Welcome to Idle Mafia! The big boss needs your help to build up the strongest mafia empire!', bgurl: 'images/games/game-bg-06.jpeg', bgtoprul: 'images/games/game-logo-06.png', bgboturl: 'images/games/game-img-06.png', appsurl: "https://apps.apple.com/us/app/idle-mafia/id1483009687", playurl: "https://play.google.com/store/apps/details?id=com.gamemaker5.idlemafia" },
];

const logos = ref(Array.from({ length: 23 }, (_, i) => `images/logos/image${i + 1}.png`))

const result = []
const logo_result = []
  let currentRow = []
  let itemsInCurrentRow = 7
  let rowIndex = 0
  logos.value.forEach((logo, index) => {
	logo_result.push(logo)
    currentRow.push(logo)
    if (currentRow.length === itemsInCurrentRow || index === logos.value.length - 1) {
      result.push(currentRow)
      currentRow = []
      rowIndex++
      itemsInCurrentRow = rowIndex === 0 || rowIndex === 7 ? 7 : 8
    }
  })
const targetDiv = ref(null);
const isVisible = ref(false);
const handleIntersect = (entries) => {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			myMethod();
		}
	});
};

const myMethod = () => {
	isVisible.value = true;
};
const bodyWidth = ref(window.innerWidth)

onMounted(() => {
	const observer = new IntersectionObserver(handleIntersect, {
		root: null,
		threshold: 0.1
	});

	if (targetDiv.value) {
		observer.observe(targetDiv.value);
	}
	window.addEventListener('resize', () => {
      bodyWidth.value = window.innerWidth
    })
	onUnmounted(() => {
		if (targetDiv.value) {
			observer.unobserve(targetDiv.value);
		}
	window.removeEventListener('resize', () => {[]})

	});
});

</script>

<style scoped>
.app {
	line-height: 1.6;
	padding-top: 82px;
}

.hero-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	justify-content: center;
	align-items: center;
}

.hero-text {
	color: white;
	font-size: 36px;
	width: 100%;
	text-align: center;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-text span {
	font-size: 29px;
}

.text-section {
	display: flex;
	justify-content: center;
	gap: 30px;
	margin: 40px 0;
	padding: 82px 10px 0 10px;
	font-size: 25px;
}

.text-column {
	flex: 1;
	max-width: 400px;
	color: #666;
	text-align: center;
}

.section-title {
	font-size: 40px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-family: "Oswald", serif;
	min-width: auto;
	height: 10vw;
	max-height: 100px;
	margin: 0 auto;
	background: url('../images/Markets-bg.jpeg') no-repeat left center /  100% 100%;
}

.section-title-t {
	display: inline-block;
	text-align: left;
	display: flex;
	justify-content: center;
	width: 85vw;
	margin: 0 auto;
	align-items: center;
	/* margin-bottom: px; */
	font-family: 'Trebuchet MS', Verdana, Helvetica, Arial, sans-serif;
}

.section-title-t div {
	display: inline-block;
	/* height: 7vw;
	line-height: 7vw; */
	padding: 14px 0;
	font-size: 2.3vw;
	/* padding: 0 20px; */
	font-family: 'Trebuchet MS', Verdana, Helvetica, Arial, sans-serif;
	align-items: center;
	width: 100%;
}

/* .section-title:nth-child(1) {
	font-size: 30px;
	color: #fff;
	font-weight: bold;
	font-style: italic;
	margin: 0;
	display: block;
} */

.games-s {
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px 0 30px 0;
}

.image-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 0;
}

.image-item {
	position: relative;
	overflow: hidden;
	aspect-ratio: 1;
	cursor: pointer;
}

.image-item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 0.3s;
}

.image-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 0.3s;
}

.image-text {
	color: white;
	font-size: 18px;
	transform: scale(0.5);
	transition: transform 0.3s;
}

.image-item:hover img {
	transform: scale(1.2);
}

.image-item:hover .image-overlay {
	opacity: 1;
}

.image-item:hover .image-text {
	transform: scale(1);
}

.partners {
	margin: 40px 0;
	padding: 0 15px;
}

.partner-grid {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 20px;
}

.partner-item {
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.partner-item img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.main-width {
	max-width: 85vw;
	margin: 0 auto;
}

/* .game-list-container {
	padding: 15px;
} */

.game-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 50px;
	list-style-type: none;
	padding: 0;
	margin: 0;
	border-radius: 30px;
}

.game-item {
	position: relative;
	border-radius: 10px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	border-radius: 30px;
}

.game-content {
	position: relative;
	width: 100%;
	height: 10%;
	padding-bottom: 100%;
	/* 1:1 Aspect Ratio */
	background-size: cover;
	background-position: center;
	border-radius: 30px;
	margin-bottom: 25px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.6);

}

.top-logo,
.bottom-image {
	position: absolute;
	transition: all 0.3s ease;
	z-index: 15;
}

.top-logo {
	top: 10px;
	left: 10px;
	max-width: 60%;
	z-index: 25;
}

.game-item:nth-child(2) .top-logo {
	top: 0px;
	left: 10px;
	max-width: 50%;
}

.bottom-image {
	bottom: 0;
	right: -15px;
	max-width: 70%;
}

.game-item:nth-child(2) .bottom-image {
	max-width: 60%;
	right: -25px;
}

.game-item:nth-child(3) .bottom-image {
	max-width: 80%;
}

.game-item:nth-child(4) .bottom-image {
	max-width: 90%;
	right: -25px;
}

.game-item:nth-child(6) .bottom-image {
	right: -25px;
}

.hover-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 0.3s ease;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 30px;
}

.games-des {
	position: relative;
	z-index: 30;
	top: 2%;
}

.games-des h3 {
	font-size: 30px;
	width: 75%;
	line-height: 30px;
}

.games-des p {
	font-size: 16px;
	width: 70%;
	line-height: 25px;
	padding: 0;
	margin: 0;
	text-align: left;
	padding-bottom: 15px;
}

.game-item:hover .hover-content {
	opacity: 1;
}

.game-item:hover .top-logo {
	transform: scale(0.6) translate(-26.25%, -36.25%);
}

.game-item:hover .bottom-image {
	transform: scale(0.7) translate(22.25%, 22.25%);
}

.play-on {
	font-size: 15px;
	font-weight: bold;
	color: #fff;
	padding-bottom: 10px;
}

h3 {
	color: white;
	margin-bottom: 10px;
	font-size: 1.2em;
}

p {
	color: #ccc;
	text-align: center;
	margin-bottom: 15px;
	font-size: 0.9em;
}

.download-buttons {
	display: flex;
	gap: 10px;
}

.btn {
	display: flex;
	align-items: center;
	text-decoration: none;
	font-weight: bold;
	transition: background-color 0.3s ease;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #fff;
	text-align: center;
}

.btn img {
	margin: 0 auto;
}

.btn-apple {
	color: #fff;
}

.btn-google {
	color: #000;
}

.btn:hover {
	opacity: 0.8;
}

.btn svg {
	margin-right: 5px;
}

.slide-entry-wrap {
	display: flex;
	justify-content: space-between;
	/* flex-wrap: wrap; */
	max-width: 85vw;
	margin: 0 auto;
}

.slide-entry-wrap img {
	width: calc(85vw / 6);
}

.slide-entry-wrap div:hover img {
	transform: scale(1.2);
}

.av-masonry-entry {
	width: 25%;
}

.av-masonry-image-container {
	width: 100%;
	height: 245px;
	text-align: center;
	background-size: cover;
	transition: all 0.3s;
	background-position: center center;
}

.av-inner-masonry-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: all 0.3s;
	opacity: 0;
	z-index: 2;
}

.av-masonry-entry {
	overflow: hidden;
}

.av-masonry-entry:hover .av-inner-masonry-content {
	opacity: 1;
	transform: translate(-50%, -50%) scale(1.2);

}

.av-masonry-entry:hover .av-masonry-image-container {
	transform: scale(1.1);
}

.av-masonry-entry-content {
	color: #fff;
}

.av-inner-masonry-sizer {
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	transition: all 0.3s;
	left: 0;
	top: 0;
	opacity: 0;
	z-index: 1;
}

.av-masonry-entry:hover .av-inner-masonry-sizer {
	opacity: 1;
}

.av-masonry-container {
	max-width: 85vw;
	margin: 0 auto;
}


.avia-content-slider-inner {
	overflow-x: auto;
}

.avia-content-slider-inner::-webkit-scrollbar {
	display: none;
}

.avia-content-slider-inner {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
/* .logo-row:nth-child(1) .logo{
	max-width: 135%;
	max-height: 135%;
}
.logo-row:nth-child(1) .logo-container:nth-child(3) .logo,
.logo-row:nth-child(2) .logo{
	max-width: 125%;
	max-height: 125%;
}
.logo-row:nth-child(2) .logo-container:nth-child(1) .logo{
	max-width: 140%;
	max-height: 140%;
}
.logo-row:nth-child(3) .logo,
.logo-row:nth-child(4) .logo,
.logo-row:nth-child(5) .logo{
	max-width: 125%;
	max-height: 125%;
}
.logo-row:nth-child(3) .logo-container:nth-child(3) .logo,
.logo-row:nth-child(4) .logo-container:nth-child(4) .logo,
.logo-row:nth-child(5) .logo-container:nth-child(1) .logo{
	max-width: 110%;
	max-height: 110%;
}
.logo-row:nth-child(4) .logo-container:nth-child(3) .logo{
	max-width: 130%;
	max-height: 130%;
} */
/* .logo-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  padding: 54px 0 11px 0;
	position: relative;
	max-width: 1310px;
	margin: 0 auto;
}

.logo-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0;
	position: relative;
}
.logo-container {
  width: 130px;
  height: 130px;
  position: relative;
  margin: 15px;
}
 */
 .logo-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 85vw;
	margin: 0 auto;
	padding: 54px 0 11px 0;
 }
.logo-frame {
  /* width: 20%; */
  width: 18%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px; */
  overflow: hidden;
}

.logo {
  /* max-width: 100%;
  max-height: 100%; */
  /* width: 100%; */
  max-width: 100%;
  height: 100%;
  /* transform: rotate(-45deg); */
  object-fit: contain;
}
.logo-grid >:nth-child(3) .logo {
	height: 130%;
}
.logo-grid >:nth-child(1) .logo,
.logo-grid >:nth-child(2) .logo,
.logo-grid >:nth-child(16) .logo,
.logo-grid >:nth-child(19) .logo  {
	height: 120%;
}
.logo-grid >:nth-child(20) .logo {
	height: 45%;
}
.logo-grid >:nth-child(4) .logo {
	height: 60%;
}
.logo-grid >:nth-child(12) .logo,
.logo-grid >:nth-child(17) .logo,
.logo-grid >:nth-child(18) .logo {
	height: 70%;
}

.logo-grid >:nth-child(9) .logo,
.logo-grid >:nth-child(10) .logo,
.logo-grid >:nth-child(11) .logo {
	height: 80%;
}

.logo-grid >:nth-child(13) .logo,
.logo-grid >:nth-child(14) .logo,
.logo-grid >:nth-child(15) .logo {
	height: 80%;
}
.logo-grid >:nth-child(21) {
	margin-left: auto;
}
.logo-grid >:nth-child(22) {
	margin: 0 3%;
}
.logo-grid >:nth-child(23) {
	margin-right: auto;
}
@media (max-width: 1250px) {
	.logo-grid{
		padding: 100px 0 50px 0;
	}
	.logo-frame{
		/* width: 100px; */
		width: 20%;
		height: 100px;
		/* margin: 10px; */
	}
}
@media (max-width: 1200px) {
	.logo-grid{
		padding: 90px 0 40px 0;
	}
	.game-item:nth-child(2) .top-logo {
		left: 10px;
		max-width: 45%;
	}

	.games-des h3 {
		font-size: 1.2rem;
		line-height: 1.3rem;
		margin-bottom: 5px;
	}

	.games-des p {
		font-size: .8rem;
		padding-bottom: 9px;
		line-height: 1.1rem;
	}

	.hover-content {
		padding: 1rem;
	}

	.games-des {
		top: 8%;
	}

	.btn {
		width: 30px;
		height: 30px;
	}

	.btn img {
		width: 60%;
		height: 60%;
	}
}
@media (max-width: 1000px) {
	.logo-grid{
		padding: 70px 0 30px 0;
	}
	.game-item:nth-child(2) .top-logo {
		left: 10px;
		max-width: 45%;
	}

	.games-des h3 {
		font-size: 1rem;
		line-height: 1rem;
		margin-bottom: 2px;
	}

	.games-des p {
		font-size: .6rem;
		line-height: 1rem;
		padding-bottom: 5px;
	}

	.hover-content {
		padding: 1rem;
	}

	.games-des {
		top: 6%;
	}

	.btn {
		width: 20px;
		height: 20px;
	}

	.btn img {
		width: 60%;
		height: 60%;
	}
	.logo-row {
    gap: 0;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
  }
}
@media (max-width: 950px) {
	.logo-frame {
		width: 25%;
		height: 80px;
	}
	/* .logo-grid {
    gap: 0;
		left: 0;
		padding: 60px 10px 10px 10px;
  }

  .logo-row {
    gap: 0;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		height: 130px;
  }
  .logo-frame {
		border: none
  }
	.logo-row:nth-child(3){
		left: 0;
	}
	.logo-row:nth-child(4){
		left: 0;
	}
	.logo-row:nth-child(5){
		left: 0;
	}
	.logo-row:nth-child(1) .logo{
	max-width: 125%;
	max-height: 125%;
}
.logo-row:nth-child(1) .logo-container:nth-child(3) .logo,
.logo-row:nth-child(2) .logo{
	max-width: 135%;
	max-height: 135%;
}
.logo-row:nth-child(2) .logo-container:nth-child(1) .logo{
	max-width: 130%;
	max-height: 130%;
}
.logo-row:nth-child(3) .logo,
.logo-row:nth-child(4) .logo{
	max-width: 120%;
	max-height: 120%;
}
.logo-row:nth-child(5) .logo{
	max-width: 135%;
	max-height: 135%;
}
.logo-row:nth-child(3) .logo-container:nth-child(3) .logo,
.logo-row:nth-child(4) .logo-container:nth-child(4) .logo,
.logo-row:nth-child(5) .logo-container:nth-child(1) .logo{
	max-width: 100%;
	max-height: 100%;
}
.logo-row:nth-child(4) .logo-container:nth-child(3) .logo{
	max-width: 120%;
	max-height: 120%;
} */
}
@media (max-width: 920px) {
  .app {
    padding-top: 60px;
  }
  .logo-grid{
		padding: 50px 0 30px 0;
	}
}
@media (max-width: 768px) {
	.app {
		padding-top: 60px;
	}
	.logo-grid{
		padding: 20px 0 20px 0;
	}
	.games-des h3 {
		font-size: 30px;
		width: 75%;
		line-height: 30px;
	}

	.games-des p {
		font-size: 16px;
		width: 70%;
		line-height: 25px;
		padding: 0;
		margin: 0;
		text-align: left;
		padding-bottom: 15px;
	}
	.games-des >:first-child {
		display: none;
	}
	.btn {
		width: 40px;
		height: 40px;
	}

	.btn img {
		width: 60%;
		height: 60%;
	}

	.game-list-container {
		width: 83%;
	}
	.game-list {
		gap: 7vw;
		margin-bottom: 1vw;
	}
	.section-title {
		font-size: 5.5vw;
	}
	.section-title-t {
		height: 10vw;
		max-height: 10vw;
		margin:10px auto;
	}

	.section-title-t div {
		font-size: 5vw;
		height: 10vw;
		max-height: 10vw;
		line-height: 10vw;
		padding: 0;
	}

	.game-list {
		grid-template-columns: repeat(1, 1fr);
	}

	.game-item {
		margin-bottom: 10px;
	}

	.game-item:nth-child(3) {
		display: none;
	}

	.games-s img {
		width: 100%;
	}

	/* .slide-entry {
		width: 40%;
	} */

	.avia-content-slider-inner {
		overflow: hidden;
		/* width: calc(220px * 5); */
	}
	.slide-entry-wrap img {
		transition: transform 0.3s;
		width: 220px;
	}
	.avia-content-slider-inner .slide-entry-wrap {
		transition: all 3s;
		flex-wrap: no-wrap;
		animation: slideAnimation 10s infinite alternate;
		max-width: 1310px;
	}

	@keyframes slideAnimation {
		0% {
			transform: translateX(5%);
		}

		100% {
			transform: translateX(calc((220px * -6) + 100vw));
		}
	}

	.av-masonry {
		width: 100%;
		overflow: hidden;
	}

	.av-masonry-container {
		max-width: none;
		width: 200%;
		overflow-x: auto;
		overflow-y: hidden;
		animation: masonryAnimation 10s infinite alternate;
	}

	@keyframes masonryAnimation {
		0% {
			transform: translateX(0);
		}

		100% {
			/* transform: translateX(calc((220px * -6) + 100vw)); */
			transform: translateX(calc((100% * -1) + 100vw));
		}
	}
		/* .logo-row {
		gap: 0;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		height: 6rem;
  } */
}
</style>