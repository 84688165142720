import { createRouter , createWebHashHistory} from 'vue-router'
import Home from '../views/HomePage.vue'
import CompanyOverview from '../views/CompanyOverview.vue'
import RussianMarket from '../views/RussianMarket.vue'
import Cooperation from '../views/CooperationView.vue'
import ContactUs from '../views/ContactUs.vue'
import SuccessfulCases from '../views/SuccessfulCases.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home, 
    meta: { className: 'home-bg' }
  },
  {
    path: '/company-overview',
    name: 'CompanyOverview',
    component: CompanyOverview, 
    meta: { className: 'about-bg' }
  },
  {
    path: '/Cases',
    name: 'Cases',
    component: SuccessfulCases, 
    meta: { className: 'about-bg' }
  },
  {
    path: '/russian-market',
    name: 'RussianMarket',
    component: RussianMarket, 
    meta: { className: 'about-bg' }
  },
  {
    path: '/cooperation',
    name: 'Vision and Plan',
    component: Cooperation, 
    meta: { className: 'about-bg' }
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs, 
    meta: { className: 'about-bg' }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

export default router